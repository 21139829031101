import { Store } from '@ping/utils';
import { isEmpty } from 'rambdax';

interface ICompliancyPendingRequestsSeenStore {
  [key: string]: boolean;
}

const COMPLIANCY_PENDING_REQUESTS_SEEN_STORE = 'COMPLIANCY_PENDING_REQUESTS_SEEN_STORE';

// --------------------------------------------------------------------------------
// COMPLIANCY PENDING REQUESTS SEEN STORE
// --------------------------------------------------------------------------------
export const useCompliancyPendingRequestsSeenStore = new Store<ICompliancyPendingRequestsSeenStore>({})
  .withPersist({ name: COMPLIANCY_PENDING_REQUESTS_SEEN_STORE, getStorage: () => window.localStorage })
  .withDevTools()
  .build();

// --------------------------------------------------------------------------------
// ENHANCE ASSETS NOTICE SELECTORS
// --------------------------------------------------------------------------------
export const getSingleCompliancyPendingRequestsSeenValueSelector = state => state;

// --------------------------------------------------------------------------------
// COMPLIANCY PENDING REQUESTS SEEN API
// --------------------------------------------------------------------------------
export const compliancyPendingRequestsSeenStore = {
  setValue: ({ lastRequestId, value }) => {
    // We need to get updated state from localStorage (?)
    const getLocalStorageState = JSON.parse(localStorage.getItem(COMPLIANCY_PENDING_REQUESTS_SEEN_STORE))?.state;

    if (
      isEmpty(getLocalStorageState) ||
      !(lastRequestId in getLocalStorageState) ||
      (getLocalStorageState && getLocalStorageState[lastRequestId])
    ) {
      useCompliancyPendingRequestsSeenStore.setState(() => ({
        ...getLocalStorageState,
        [lastRequestId]: value,
      }));
    }
  },
};
