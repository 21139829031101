import router from 'next/router';
import { isEmpty } from 'rambdax';

import { Store } from '@ping/utils';

import { userInformationStore } from './userInformation.store';

import type { TokenResponse } from '@ting/app-auth';

const MILLISECONDS = 1_000 as const;

export type ITokenStore = Omit<TokenResponse, 'toJson' | 'isValid'>;

// --------------------------------------------------------------------------------
// TOKEN STORE
// --------------------------------------------------------------------------------
export const useTokenStore = new Store<ITokenStore>({} as ITokenStore).withPersist({ name: 'TOKEN_STORE' }).build();

// --------------------------------------------------------------------------------
// TOKEN STORE SELECTORS
// --------------------------------------------------------------------------------
export const userAccessTokenSelector = (state: ITokenStore) => state?.accessToken;

export const isUserLoggedInSelector = (state: ITokenStore) => !!state?.accessToken?.length;

export const userIdTokenSelector = (state: ITokenStore) => state?.idToken;

export const isTokenValidSelector = (state: ITokenStore) => {
  const { issuedAt, expiresIn } = state || {};
  //
  // validate the token
  const expireDateInMilliseconds = (issuedAt + +expiresIn) * MILLISECONDS;

  return expireDateInMilliseconds > Date.now();
};

export const isTokenNeededToBeRefreshedSelector = (state: ITokenStore) =>
  !isEmpty(state) && !isTokenValidSelector(state);

// --------------------------------------------------------------------------------
// TOKEN STORE API
// --------------------------------------------------------------------------------
export const tokenStore = {
  setTokenResponse: (value: ITokenStore, replace = true) => useTokenStore.setState(value, replace),
  revokeToken: () => useTokenStore.setState({}, true),
  getTokenResponse: () => useTokenStore.getState(),
  getAccessToken: () => userAccessTokenSelector(useTokenStore.getState()),
  getIdToken: () => userIdTokenSelector(useTokenStore.getState()),
};

// --------------------------------------------------------------------------------
// TOKEN STORE SUBSCRIPTIONS
// --------------------------------------------------------------------------------
useTokenStore.subscribe(state => !state && router.push('/'));
useTokenStore.subscribe(state => !state && userInformationStore.revokeUserInformation());
