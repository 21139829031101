import { useEffect, useState } from 'react';
import { getDiffTimeFromNow, getTime } from '@ping/utils';

/**
 * @method useCountUp
 * @description Hook to count-up from the provided number.
 * @param targetDate accepts a number in milliseconds
 */

const MILLISECOND = 1_000 as const;

const useCountUp = (targetDate: number) => {
  const [countUp, setCountUp] = useState(getDiffTimeFromNow(targetDate));

  useEffect(() => {
    const interval = setInterval(() => {
      setCountUp(getDiffTimeFromNow(targetDate));
    }, MILLISECOND);
    return () => clearInterval(interval);
  }, [targetDate]);

  // Get the time in days, hours, minutes, etc.
  return getTime(countUp);
};

export { useCountUp };
