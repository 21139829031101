import { ECurrency } from '@ping/enums';
import Big, { BigSource } from 'big.js';

import { countNumberDecimals } from './number-helper.util';

const MAXIMUM_SCALE = 18 as const;
const PRISTINE_VALUE = '' as const;
const WORD_PATTERN = /(?=[A-Z])|\s+|[-_./]/;

/**
 * It takes a string or a Date object and returns a formatted date string
 * @param {string | Date} value - string | Date - The value to format.
 */
export const formatDate = (value: string | Date) => {
  if (!value) return null;

  const date = new Date(value);
  const formatted = {
    time: Intl.DateTimeFormat('default', { timeStyle: 'medium', hour12: false }).format(date),
    date: Intl.DateTimeFormat('default', { dateStyle: 'long' }).format(date),
  };

  return `${formatted.date} - ${formatted.time}`;
};

/**
 * It takes a string or a Date object and returns a formatted date string
 * @param {string | Date} value - string | Date - The value to format.
 */
export const formatCompactDate = (value: string | Date) => {
  if (!value) return null;

  const date = new Date(value);

  return Intl.DateTimeFormat('default', { dateStyle: 'long' }).format(date);
};

/**
 * It takes a string or a Date object and returns a formatted time string
 * @param {string | Date} value - string | Date - The value to format.
 */
export const formatTime = (value: string | Date) => {
  if (!value) return null;

  const date = new Date(value);

  return Intl.DateTimeFormat('default', { timeStyle: 'medium', hour12: true }).format(date);
};

/**
 * It formats a number using Intl.NumberFormat with optional options and returns null if the
 * value is null or undefined.
 * @param {BigSource} input - The number that needs to be formatted.
 * @param [options] - `options` is an optional parameter of type `Intl.NumberFormatOptions`. It allows
 * you to customize the formatting of the number, such as specifying the:
 * - number of decimal places
 * - grouping separator
 * - decimal separator\
 * If `options` is not provided, the function will use the default options
 */
export const formatNumber = (input: BigSource, options?: Intl.NumberFormatOptions) => {
  try {
    //
    // Unlike `Number.prototype.toFixed`, which returns exponential notation if a number is greater or equal to 10**21, `Big.toFixed` will always return normal notation.
    const fixedValue = Big(input).toFixed() as unknown as number;
    return Intl.NumberFormat('en', { maximumFractionDigits: MAXIMUM_SCALE, ...options }).format(fixedValue);
  } catch {
    return input === PRISTINE_VALUE ? PRISTINE_VALUE : null;
  }
};

/**
 * It formats a number as a cryptocurrency with a maximum of 6 decimal places
 * @param {number | string} value - number | string
 */
export const formatCrypto = (value: number | string, symbol: string) => {
  if (value === null || value === undefined) return null;

  const amount = formatNumber(value, {
    maximumFractionDigits: 6,
    minimumFractionDigits: 0,
  });

  return `${amount} ${symbol?.toUpperCase()}`;
};

/**
 * It formats a number as a fiat currency, using the currency code provided with a maximum of 2 decimal places
 * @param {number | string} value - The value to be formatted.
 * @param {ECurrency} currency - The currency to use.
 */
export const formatFiat = (value: number | string, currency: ECurrency = ECurrency.USD) => {
  if (value === null || value === undefined) return null;

  return formatNumber(value, {
    currency,
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: 'currency',
  });
};

const formatAddSuffix = (number: number, separateNumberFromSuffix = false, precision = 2) => {
  const map = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
  ];

  const found = map.find(x => Math.abs(number) >= x.threshold);
  if (found) {
    const numberWithoutSuffix = (number / found.threshold).toFixed(precision);
    const numberWithSuffix = formatNumber(numberWithoutSuffix) + found.suffix;

    if (separateNumberFromSuffix) {
      return [Number(numberWithoutSuffix), found.suffix];
    } else {
      return numberWithSuffix;
    }
  }

  if (separateNumberFromSuffix) {
    return [number, ''];
  }

  return number;
};

export const formatFiatReverse = (currency: ECurrency = ECurrency.CHF, value: number | string) => {
  if (value === null || value === undefined) return null;

  return formatNumber(value, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: 'currency',
    currency,
  });
};

/**
 * It takes a string as input and splits it into an array of words.
 * @param {string} value - The string that you want to split.
 */
const splitStringToWords = (value: string) => {
  return value.split(WORD_PATTERN);
};

/**
 * It takes a string as input and converts it to PascalCase
 * format.
 * @param {string} value - The string that you want to convert to PascalCase.
 */
const formatStringToPascalCase = (value: string) => {
  const FIRST = 0 as const;
  const SECOND_TO_END = 1 as const;
  const PASCAL_CASE_DELIMITER = '' as const;

  return splitStringToWords(value)
    .map(word => word.charAt(FIRST).toUpperCase() + word.slice(SECOND_TO_END))
    .join(PASCAL_CASE_DELIMITER);
};

/**
 * It takes a string as input and converts it to TitleCase
 * format.
 * @param {string} value - The string that you want to convert to TitleCase.
 */
const formatStringToTitleCase = (value: string) => {
  const FIRST = 0 as const;
  const SECOND_TO_END = 1 as const;
  const TITLE_CASE_DELIMITER = ' ' as const;

  return splitStringToWords(value)
    .map(word => word.charAt(FIRST).toUpperCase() + word.slice(SECOND_TO_END))
    .join(TITLE_CASE_DELIMITER);
};

/**
 * Formats a number in exponential notation to a fixed precision.
 *
 * @param {BigSource} input - the number to format
 * @param {number} [precision] - the optional precision to format to
 * @return {string} the formatted number as a string
 */
export const formatExponential = (input: BigSource, precision?: number) => {
  try {
    return Big(input).toFixed(precision ?? countNumberDecimals(input));
  } catch {
    return '0';
  }
};

export const format = Object.freeze({
  date: formatDate,
  compactDate: formatCompactDate,
  time: formatTime,
  number: formatNumber,
  addSuffix: formatAddSuffix,
  exponential: formatExponential,
  crypto: formatCrypto,
  fiat: formatFiat,
  fiatReverse: formatFiatReverse,
  string: {
    splitToWords: splitStringToWords,
    toPascalCase: formatStringToPascalCase,
    toTitleCase: formatStringToTitleCase,
  },
});
