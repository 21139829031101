import { ActivityStatus, ComplianceStepType, UserReviewStatusType } from '@ping/api';
import { Store } from '@ping/utils';

import type { ProfileResponse } from '@ping/api';
import { compliancyPendingRequestsSeenStore } from './compliancy-pending-requests-seen.store';

export type IUserInformationStore = ProfileResponse & {
  isTerminated?: boolean;
  isFrozen?: boolean;
  complianceStep?: ComplianceStepType;
};

// --------------------------------------------------------------------------------
// USER INFORMATION STORE
// --------------------------------------------------------------------------------
export const useUserInformationStore = new Store<IUserInformationStore>({} as IUserInformationStore)
  .withPersist({ name: 'USER_INFORMATION_STORE' })
  .withDevTools()
  .build();

// --------------------------------------------------------------------------------
// USER INFORMATION STORE SELECTORS
// --------------------------------------------------------------------------------
export const isUserVerificationInProgressSelector = (state: IUserInformationStore) =>
  Boolean(state?.verificationIsInProgress);

/*
 * For clarification, previously, we used isUserLoggedInSelector in the token store to check whether
 * the user is logged in or not but there was an issue when the user didn't complete the KYC process.
 * Moreover, in the back-end, they do not create a profile for that user but return accessToken to us,
 * and since we only checked accessToken to detect if the user is logged in, we had some issues.
 * This is why I added this selector which will help us to recognize we have profile data in our state and
 * ensure the user is fully logged in.
 */
export const isUserDataExistSelector = (state: IUserInformationStore) => 'roles' in state;

export const isKYCedRequiredSelector = (state: IUserInformationStore) => !!state?.verificationError;

export const isUserAccountTerminatedSelector = (state: IUserInformationStore) => !!state?.isTerminated;

export const isUserAccountFrozenSelector = (state: IUserInformationStore) => !!state?.isFrozen;

export const isUserVerifiedSelector = (state: IUserInformationStore) => Boolean(state?.verificationLevel);

export const verificationLevelSelector = (state: IUserInformationStore) => state?.verificationLevel;

export const isUserAdminSelector = (state: IUserInformationStore) =>
  Boolean(state?.roles?.includes('admin') || state?.roles?.includes('support')); // TODO: why also `support`?

export const isUserComplianceReviewerSelector = (state: IUserInformationStore) =>
  Boolean(state?.roles?.map(role => role.toLowerCase()).includes('ComplianceReviewer'.toLowerCase()));

export const userCoreIDSelector = (state: IUserInformationStore) => state?.coreId;
export const userIdSelector = (state: IUserInformationStore) => state?.id;

export const userTierSelector = (state: IUserInformationStore) => state?.complianceTier;

export const userActivityStatusSelector = (state: IUserInformationStore) => state?.activityStatus;

export const isUserTradeDataViewerSelector = (state: IUserInformationStore) =>
  Boolean(state?.roles?.map(role => role.toLowerCase()).includes('TradeDataViewer'.toLowerCase()));

export const isUserSupportSelector = (state: IUserInformationStore) =>
  Boolean(state?.roles?.map(role => role.toLowerCase()).includes('support'));

// Activity Status:
export const isUserActivityStatusActiveSelector = (state: IUserInformationStore) =>
  state?.activityStatus === ActivityStatus.Active || state?.activityStatus === ActivityStatus.Limited;
export const isUserActivityStatusFrozenSelector = (state: IUserInformationStore) =>
  state?.activityStatus === ActivityStatus.Frozen;
export const isUserActivityStatusBannedSelector = (state: IUserInformationStore) =>
  state?.activityStatus === ActivityStatus.Banned;
export const isUserActivityStatusWaitingForDataSelector = (state: IUserInformationStore) =>
  state?.activityStatus === ActivityStatus.WaitingForData;
export const isUserActivityStatusWaitingForReviewSelector = (state: IUserInformationStore) =>
  state?.activityStatus === ActivityStatus.WaitingForReview;

// Compliance Step:
export const isUserAtNoDataStepSelector = (state: IUserInformationStore) =>
  state?.complianceStep === ComplianceStepType.NoData;
export const isUserAtEntityTypeDeclarationStepSelector = (state: IUserInformationStore) =>
  state?.complianceStep === ComplianceStepType.EntityTypeDeclaration;
export const isUserAtKycRequestStepSelector = (state: IUserInformationStore) =>
  state?.complianceStep === ComplianceStepType.KycRequest;
export const isUserAtFormAStepSelector = (state: IUserInformationStore) =>
  state?.complianceStep === ComplianceStepType.FormA;
export const isUserAtSourceOfFundsStepSelector = (state: IUserInformationStore) =>
  state?.complianceStep === ComplianceStepType.SourceOfFunds;
export const isUserAtPendingForReviewStepSelector = (state: IUserInformationStore) =>
  state?.complianceStep === ComplianceStepType.PendingForReview;

export const isUserWaitingOrPendingForReviewSelector = (state: IUserInformationStore) =>
  isUserActivityStatusWaitingForReviewSelector(state) || isUserAtPendingForReviewStepSelector(state);

export const documentExpirationDateSelector = (state: IUserInformationStore) => state?.documentExpirationDate;

export const isThereAnyPendingRequestToNotifyUserSelector = (state: IUserInformationStore) => {
  const statuses: UserReviewStatusType[] = [
    UserReviewStatusType.Denied,
    UserReviewStatusType.PendingForReview,
    UserReviewStatusType.RequestedMoreData,
  ];

  return statuses.includes(state?.lastRequest?.status);
};

export const complianceStepSelector = (state: IUserInformationStore) => state?.complianceStep;

export const lastRequestStatusSelector = (state: IUserInformationStore) => state?.lastRequest?.status;
export const isLastRequestStatusRequestedMoreDataSelector = (state: IUserInformationStore) =>
  state?.lastRequest?.status === UserReviewStatusType.RequestedMoreData;
export const lastRequestIdSelector = (state: IUserInformationStore) => state?.lastRequest?.id;

// --------------------------------------------------------------------------------
// USER INFORMATION STORE API
// --------------------------------------------------------------------------------
export const userInformationStore = {
  setUserInformation: (input: IUserInformationStore, replace = true) => {
    const state = useUserInformationStore.getState();
    const complianceStep = state?.complianceStep; // TODO: extract the "complianceStep" out of this the store
    const isLastRequestDistinct = state?.lastRequest?.id !== input?.lastRequest?.id;

    if (isLastRequestDistinct && state?.lastRequest?.id) {
      compliancyPendingRequestsSeenStore.setValue({ lastRequestId: state?.lastRequest?.id, value: true });
    }

    return useUserInformationStore.setState({ ...input, complianceStep }, replace);
  },
  setComplianceStep: (complianceStep: ComplianceStepType) => useUserInformationStore.setState({ complianceStep }),
  revokeUserInformation: () => useUserInformationStore.setState({}, true),
  isUserVerified: () => isUserVerifiedSelector(useUserInformationStore.getState()),
  getComplianceStep: () => complianceStepSelector(useUserInformationStore.getState()),
  getUserActivityStatus: () => userActivityStatusSelector(useUserInformationStore.getState()),
  changeLastRequestToPending: () =>
    useUserInformationStore.setState({
      lastRequest: { ...useUserInformationStore.getState().lastRequest, status: UserReviewStatusType.PendingForReview },
    }),
  isUserSupport: () => isUserSupportSelector(useUserInformationStore.getState()),
};
