import { useEffect } from 'react';
import { clsx } from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { getNextStepCompliance, UserReviewStatusType } from '@ping/api';
// import TrendUpArrowIcon from '@ping/assets/Icon/trend-up-arrow.svg';
// import ArrowRepeatIcon from '@ping/assets/Icon/arrow-repeat.svg';
import ReversedArrowsIcon from '@ping/assets/Icon/reversedArrows.svg';
// import LevelArrowIcon from '@ping/assets/Icon/levelArrow.svg';
import AvatarIcon from '@ping/assets/Icon/avatar.svg';
import ClockIcon from '@ping/assets/Icon/clock.svg';
import HistoryIcon from '@ping/assets/Icon/history.svg';
import LevelsIcon from '@ping/assets/Icon/levels.svg';
import LogoutIcon from '@ping/assets/Icon/logout.svg';
import PortfolioIcon from '@ping/assets/Icon/portfolio.svg';
import ReferralsIcon from '@ping/assets/Icon/referrals.svg';
import SettingsIcon from '@ping/assets/Icon/settings.svg';
import SupportIcon from '@ping/assets/Icon/support.svg';
import MarketIcon from '@ping/assets/Icon/swap.svg';
import TradingIcon from '@ping/assets/Icon/trading-limits.svg';
import { LOGIN_CALLBACK_PATH } from '@ping/authorization/constants';
import { handleProfile, loginSideEffect } from '@ping/authorization/side-effects';
import { Avatar, LanguageCurrencyDialog } from '@ping/components';
import NavbarDropdownItems, { TNavbarDropdownItem } from '@ping/components/Header/NavbarDropdownItems';
import NavbarLink from '@ping/components/Header/NavLink';
import { PendingApprovalModal } from '@ping/components/PendingApprovalModal';
import { APP_VERSION } from '@ping/configs';
import { DEFAULT_CURRENCIES } from '@ping/constants/default-currencies.constant';
import { signoutReset, t } from '@ping/helpers';
import { useIsUserLoggedIn, useMobile, useSelectedCurrency } from '@ping/hooks';
import {
  languageModalStore,
  selectIsLanguageModalOpen,
  selectLanguageModalActiveTab,
  useLanguageModalStore,
} from '@ping/stores/languageModal.store';
import {
  importantNoticeModalRemoteStateSelector,
  pendingApprovalModalRemoteStateSelector,
  useModalStore,
} from '@ping/stores/modal.store';
import {
  isSelectedThemeDarkSelector,
  selectedLanguageSelector,
  usePreferencesStore,
} from '@ping/stores/preferences.store';
import {
  isThereAnyPendingRequestToNotifyUserSelector,
  isUserActivityStatusActiveSelector,
  isUserAdminSelector,
  isUserComplianceReviewerSelector,
  lastRequestStatusSelector,
  userCoreIDSelector,
  userInformationStore,
  userTierSelector,
  useUserInformationStore,
  isUserTradeDataViewerSelector,
  lastRequestIdSelector,
  isUserSupportSelector,
} from '@ping/stores/userInformation.store';
import {
  compliancyPendingRequestsSeenStore,
  getSingleCompliancyPendingRequestsSeenValueSelector,
  useCompliancyPendingRequestsSeenStore,
} from '@ping/stores/compliancy-pending-requests-seen.store';
import { ButtonA11Y } from '@ping/uikit';
import { LinkWithDropdown } from '@ping/components/Header/LinkWithDropdown';
import { DropDownLinkMobileModalTrigger } from '@ping/components/Header/DropDownLinkMobileModalTrigger';

import style from './style.module.scss';

const PendingApprovalTriggerButton = () => {
  const pendingApprovalModalRemoteState = useModalStore(pendingApprovalModalRemoteStateSelector);
  const activeRequestStatus = useUserInformationStore(lastRequestStatusSelector);
  const isThereAnyPendingRequestToNotifyUser = useUserInformationStore(isThereAnyPendingRequestToNotifyUserSelector);
  const getSingleCompliancyPendingRequestsSeenValue = useCompliancyPendingRequestsSeenStore(
    getSingleCompliancyPendingRequestsSeenValueSelector
  );
  const lastRequestId = useUserInformationStore(lastRequestIdSelector);

  useEffect(() => {
    if (isThereAnyPendingRequestToNotifyUser) {
      compliancyPendingRequestsSeenStore.setValue({ lastRequestId, value: true });
    }
  }, [isThereAnyPendingRequestToNotifyUser, activeRequestStatus]);

  if (getSingleCompliancyPendingRequestsSeenValue[lastRequestId] && isThereAnyPendingRequestToNotifyUser) {
    return (
      <ButtonA11Y
        className={clsx(style['pending-approval--btn'], {
          [style['is-rejected']]: activeRequestStatus === UserReviewStatusType.Denied,
          [style['is-more-data-needed']]: activeRequestStatus === UserReviewStatusType.RequestedMoreData,
          [style['is-pending-for-review']]: activeRequestStatus === UserReviewStatusType.PendingForReview,
        })}
        onPress={pendingApprovalModalRemoteState.open}
      >
        <ClockIcon />
        <span className={style['pending-approval--btn-text']}>
          {activeRequestStatus === UserReviewStatusType.Denied && t('Request was rejected')}
          {activeRequestStatus === UserReviewStatusType.RequestedMoreData && t('Provide more data')}
          {activeRequestStatus !== UserReviewStatusType.Denied &&
            activeRequestStatus !== UserReviewStatusType.RequestedMoreData &&
            t('Pending for review')}
        </span>
      </ButtonA11Y>
    );
  }

  return null;
};

// const TRADE_DROPDOWN_LINKS = [
//   {
//     href: '/trade',
//     title: t('Spot trading'),
//     subtitle: t('Trade your assets on the spot'),
//     icon: <TrendUpArrowIcon />,
//   },
//   {
//     href: '/convert',
//     title: t('Convert'),
//     subtitle: t('Quickly convert your assets'),
//     icon: <ArrowRepeatIcon />,
//   },
// ];

const PORTFOLIO_DROPDOWN_LINKS = [
  {
    href: '/user/portfolio',
    title: t('Overview'),
    subtitle: t('Take a look at your assets'),
    icon: <PortfolioIcon />,
  },
  {
    href: '/user/portfolio?modal=send_receive&tab=send',
    title: t('Send & Receive'),
    subtitle: t('Send or receive assets easily'),
    icon: <ReversedArrowsIcon />,
  },
  // {
  //   href: '/user/portfolio/ramp',
  //   title: t('Buy & Sell'),
  //   subtitle: t('Use fiat to buy or sell crypto'),
  //   icon: <LevelArrowIcon />,
  // },
];

export const Header = () => {
  const isUserLoggedIn = useIsUserLoggedIn();
  const { setLanguageModal } = languageModalStore;
  const isMobile = useMobile();
  const isLMOpen = useLanguageModalStore(selectIsLanguageModalOpen);
  const LMActiveTab = useLanguageModalStore(selectLanguageModalActiveTab);
  const isSelectedThemeDark = usePreferencesStore(isSelectedThemeDarkSelector);
  const importantNoticeModal = useModalStore(importantNoticeModalRemoteStateSelector);
  const isUserActivityStatusActive = useUserInformationStore(isUserActivityStatusActiveSelector);
  const isUserComplianceReviewer = useUserInformationStore(isUserComplianceReviewerSelector);
  const isUserTradeDataViewer = useUserInformationStore(isUserTradeDataViewerSelector);
  const isUserAdmin = useUserInformationStore(isUserAdminSelector);
  const isUserSupport = useUserInformationStore(isUserSupportSelector);

  const handleGetNextStep = async () => {
    const nextStep = await getNextStepCompliance();

    userInformationStore.setComplianceStep(nextStep);
  };

  const handleLCModal = (value, activeTab?: Parameters<typeof setLanguageModal>[1]) => {
    setLanguageModal(value, activeTab);
  };

  useEffect(() => {
    if (isUserLoggedIn) {
      handleGetNextStep();
      handleProfile();
    }
  }, [isUserLoggedIn]);

  return (
    <header className={clsx(style['header'], 'light-bb')}>
      <Navbar expand='lg'>
        <Navbar.Brand>
          <Link href={isUserLoggedIn && !isUserActivityStatusActive ? '' : '/trade'}>
            {isMobile ? (
              <img className={style['logo-mobile']} src={`/img/logo-ping-icon.svg`} alt='logo' />
            ) : (
              <img
                className={style['logo']}
                src={`/img/logo-${isSelectedThemeDark ? 'dark' : 'light'}.svg`}
                alt='logo'
              />
            )}
          </Link>
          <img
            className={style['app-stage-badge']}
            src={`/img/${APP_VERSION.toLowerCase()}-badge.svg`}
            alt={`${APP_VERSION.toLowerCase()} badge`}
            onClick={importantNoticeModal.open}
          />
        </Navbar.Brand>

        {!(isUserComplianceReviewer || (isUserTradeDataViewer && !isUserAdmin)) && (
          <Nav className={clsx(style['header__navbar'], style['header__links-nav'])}>
            {/*<LinkWithDropdown*/}
            {/*title={t('Trade')}*/}
            {/*disabled={(isUserLoggedIn && !isUserActivityStatusActive) || isUserSupport}*/}
            {/*link={TRADE_DROPDOWN_LINKS}*/}
            {/*/>*/}
            <NavbarLink
              options={[
                {
                  title: t('Trade'),
                  href: '/trade',
                  className: style['header__link'],
                  disabled: (isUserLoggedIn && !isUserActivityStatusActive) || isUserSupport,
                },
                {
                  title: t('Markets'),
                  href: '/markets',
                  className: style['header__link'],
                  disabled: isUserLoggedIn && !isUserActivityStatusActive,
                },
              ]}
            />
            <LinkWithDropdown title={t('Portfolio')} disabled={!isUserLoggedIn} link={PORTFOLIO_DROPDOWN_LINKS} />
          </Nav>
        )}

        <Nav className={clsx(style['header__navbar'], style['nav-user'])} aria-live='polite'>
          {!isMobile && isUserLoggedIn && <PendingApprovalTriggerButton />}
          {isUserLoggedIn ? <ProfileDropdownMenu /> : <GetStartedButton data-testid='login-button-nav' />}
        </Nav>
      </Navbar>

      <LanguageCurrencyDialog isOpen={isLMOpen} activeTab={LMActiveTab} onClose={() => handleLCModal(false)} />
      {isUserLoggedIn && <PendingApprovalModal />}
    </header>
  );
};

const ProfileDropdownMenu = () => {
  const selectedCurrency = useSelectedCurrency();
  const isUserAdmin = useUserInformationStore(isUserAdminSelector);
  const isUserComplianceReviewer = useUserInformationStore(isUserComplianceReviewerSelector);
  const isUserTradeDataViewer = useUserInformationStore(isUserTradeDataViewerSelector);
  const userTier = useUserInformationStore(userTierSelector);
  const userCoreID = useUserInformationStore(userCoreIDSelector);
  const { setLanguageModal } = languageModalStore;
  const selectedLanguage = usePreferencesStore(selectedLanguageSelector);
  const isLMOpen = useLanguageModalStore(selectIsLanguageModalOpen);
  const isMobile = useMobile();

  const handleLogout = event => {
    event.preventDefault();
    signoutReset();
  };

  if (isUserComplianceReviewer) {
    return (
      <NavDropdown
        data-testid='profile-dropdown'
        id={style['nav-dropdown-profile']}
        title={<Avatar userCoreID={userCoreID} tier='Officer' />}
      >
        <NavbarDropdownItems
          className={style['header__dropdown-item']}
          options={[
            {
              title: t('Logout'),
              href: '',
              icon: <LogoutIcon className={style['nav-item-icon']} />,
              className: style['header__dropdown-item'],
              onClick: handleLogout,
            },
          ]}
        />
      </NavDropdown>
    );
  }

  if (isUserTradeDataViewer) {
    return (
      <NavDropdown
        data-testid='profile-dropdown'
        id={style['nav-dropdown-profile']}
        title={<Avatar userCoreID={userCoreID} tier='TradeViewer' />}
      >
        <NavbarDropdownItems
          className={style['header__dropdown-item']}
          options={[
            {
              title: t('Logout'),
              href: '',
              icon: <LogoutIcon className={style['nav-item-icon']} />,
              className: style['header__dropdown-item'],
              onClick: handleLogout,
            },
          ]}
        />
      </NavDropdown>
    );
  }

  const navDropDownOptions: TNavbarDropdownItem[] = [
    ...(!isUserComplianceReviewer && !isUserTradeDataViewer && isMobile
      ? [
          {
            title: <DropDownLinkMobileModalTrigger title={t('Portfolio')} link={PORTFOLIO_DROPDOWN_LINKS} />,
            href: '/user/portfolio',
            icon: <PortfolioIcon className={style['nav-item-icon']} />,
          },
        ]
      : ([] as any)),
    {
      title: t('History'),
      href: '/user/history',
      icon: <HistoryIcon className={style['nav-item-icon']} />,
    },
    {
      title: t('Referrals'),
      href: '/user/referrals',
      icon: <ReferralsIcon className={style['nav-item-icon']} />,
    },
    {
      title: t('Settings'),
      href: '/user/settings',
      icon: <SettingsIcon className={style['nav-item-icon']} />,
    },
    {
      title: t('Support'),
      href: '/user/support',
      icon: <SupportIcon className={style['nav-item-icon']} />,
    },
    ...(isUserAdmin
      ? [
          {
            title: t('Compliance'),
            href: '/admin/compliance',
            icon: <AvatarIcon className={style['nav-item-icon']} />,
          },
          {
            title: t('Admin'),
            href: '/admin',
            icon: <AvatarIcon className={style['nav-item-icon']} />,
          },
        ]
      : ([] as any)),
    {
      title: t('Tiers'),
      href: '/user/tiers',
      icon: <LevelsIcon className={style['nav-item-icon']} />,
      label: `${t(userTier?.replace('Tier', 'TIER '))}`,
    },
    ...(!isUserComplianceReviewer && !isUserTradeDataViewer && isMobile
      ? [
          {
            // title: <DropDownLinkMobileModalTrigger title={t('Trade')} link={TRADE_DROPDOWN_LINKS} />,
            // href: '',
            title: t('Trade'),
            href: '/trade',
            hasTopSeparator: true,
            icon: <TradingIcon className={style['nav-item-icon']} />,
            hasArrow: true,
          },
          {
            title: t('Markets'),
            href: '/markets',
            icon: <MarketIcon className={style['nav-item-icon']} />,
            hasArrow: true,
          },
        ]
      : ([] as any)),
    {
      title: selectedLanguage,
      href: '',
      hasTopSeparator: true,
      className: style['header__dropdown-item'],
      onClick: () => setLanguageModal(!isLMOpen),
      hasArrow: true,
    },
    {
      title: `${selectedCurrency} - ${
        DEFAULT_CURRENCIES.find(currency => currency.value === selectedCurrency.toLowerCase())?.symbol
      }`,
      href: '',
      className: style['header__dropdown-item'],
      onClick: () => setLanguageModal(!isLMOpen, 'currency'),
      hasArrow: true,
    },
    {
      title: t('Logout'),
      href: '',
      hasTopSeparator: true,
      icon: <LogoutIcon className={style['nav-item-icon']} />,
      className: style['header__dropdown-item'],
      onClick: handleLogout,
    },
  ];

  return (
    <NavDropdown
      data-testid='profile-dropdown'
      id={style['nav-dropdown-profile']}
      title={<Avatar userCoreID={userCoreID} tier={userTier} />}
    >
      {isMobile && <PendingApprovalTriggerButton />}
      <NavbarDropdownItems className={style['header__dropdown-item']} options={navDropDownOptions} />
    </NavDropdown>
  );
};

export const GetStartedButton = props => {
  const { pathname } = useRouter();
  const isCallbackPath = pathname === LOGIN_CALLBACK_PATH;

  return (
    <ButtonA11Y
      {...props}
      onPress={loginSideEffect}
      isDisabled={isCallbackPath}
      className={style['header__get-started-button']}
      primary
    >
      {t('Get Started')}
    </ButtonA11Y>
  );
};
